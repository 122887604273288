<template>
  <div @touchmove.prevent>
    <scroll ref="scroll" class="scroll" :data="data">
      <div>
        <empty :description="$route.params.msg" image="error"></empty>
      </div>
    </scroll>
  </div>
</template>

<script>
import Scroll from '@/components/scroll/scroll'
import { Empty } from 'vant'

export default {
  name: 'Search',
  components: {
    Scroll,
    Empty
  },
  activated () {
    setTimeout(() => {
      this.$refs.scroll.refresh()
    }, 100)
  },
  data () {
    return {
      data: []
    }
  }
}
</script>

<style scoped lang="stylus">
.scroll
  position absolute
  z-index 10
  top 0
  left 0
  right 0
  bottom 0
  bottom constant(safe-area-inset-bottom)
  bottom env(safe-area-inset-bottom)
  overflow hidden
  background rgba(255, 255, 255, 1)
</style>
